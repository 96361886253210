import request from '@/utils/request'

export function fetchList(query) {
  return request({
    url: '/Api/table/list',
    method: 'get',
    params: query
  })
}

export function fetchArticle(id) {
  return request({
    url: '/Api/table/form',
    method: 'get',
    params: { id }
  })
}

export function fetchPv(pv) {
  return request({
    url: '/Api/table/pv',
    method: 'get',
    params: { pv }
  })
}

export function createArticle(data) {
  return request({
    url: '/Api/table/create',
    method: 'post',
    data
  })
}

export function updateArticle(data) {
  return request({
    url: '/Api/table/update',
    method: 'post',
    data
  })
}
export function deleteArticle(id) {
  return request({
    url: '/Api/table/delete',
    method: 'delete',
    params: id
  })
}

export function getCustomerList() {
  return request({
    url: '/Api/table/customerInfo',
    method: 'get',
  })
}

export function createCustomerList(data) {
  return request({
    url: '/Api/table/createCustomerInfo',
    method: 'post',
    data
  })
}
export function updateCustomerList(data) {
  return request({
    url: '/Api/table/updateCustomerInfo',
    method: 'post',
    data
  })
}
export function deleteCustomerList(id) {
  return request({
    url: '/Api/table/deleteCustomerInfo',
    method: 'delete',
    params: id
  })
}
export function getComponentsList(params) {
  return request({
    url: '/Api/table/componentsInfo',
    method: 'get',
    params
  })
}

export function createComponentsList(data) {
  return request({
    url: '/Api/table/createComponentsInfo',
    method: 'post',
    data
  })
}
export function updateComponentsList(data) {
  return request({
    url: '/Api/table/updateComponentsInfo',
    method: 'post',
    data
  })
}
export function bulkUpdate(data) {
  return request({
    url: '/Api/table/bulkUpdate',
    method: 'post',
    data
  })
}
export function deleteComponentsList(id) {
  return request({
    url: '/Api/table/deleteComponentsInfo',
    method: 'delete',
    params: id
  })
}
export function uploadExtra(data, id) {
  return request({
    url: '/Api/table/upload',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: data,
    params: id
  })
}
export function uploadSupplierExtra(data, id) {
  return request({
    url: '/Api/table/uploadSupplier',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: data,
    params: id
  })
}
export function bulkUpdate1(data) {
  return request({
    url: '/Api/table/bulkUpdate1',
    method: 'post',
    data
  })
}